import { Center, Spinner } from "@chakra-ui/react";
import qoreContext from "app/common/qoreContext";
import { useRouter } from "next/router";
import React from "react";

const IndexPage = () => {
  const router = useRouter();
  const { user } = qoreContext.useCurrentUser();
  const [orgId, setOrgId] = React.useState();
  const [teamId, setTeamId] = React.useState();
  const { data: organization } = qoreContext
    .view("organization-list")
    .useListRow();

  const { data: team } = qoreContext
    .view("team-list")
    .useListRow({ params: { organizationId: organization[0]?.id } });

  React.useEffect(() => {
    setOrgId(organization[0]?.id);
    setTeamId(team[0]?.id);
  }, [organization, team]);

  React.useEffect(() => {
    if (user && orgId && teamId) {
      router.push(`/organization/${orgId}/team/${teamId}`);
    }
  }, [user, orgId, teamId]);

  return (
    <Center w="100vw" h="100vh">
      <Spinner
        speed="0.8s"
        size="lg"
        thickness="3px"
        color="blue.400"
        emptyColor="gray.200"
      />
    </Center>
  );
};

export default IndexPage;
